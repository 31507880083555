import request from './request'

function getlist(data) {
  return request({
    url: '/policyList',
    method: 'post',
    data: data
  })
}
function policyDetail(data) {
  return request({
    url: '/policyDetail',
    method: 'get',
    params: data
  })
}
function getUserId(data) {
  return request({
    url: '/getUserId',
    method: 'get',
    params: data
  })
}
function getSubscribe(data) {
  return request({
    url: '/getSubscribe',
    method: 'get',
    params: data
  })
}
function addSubscribe(data) {
  return request({
    url: '/addSubscribe',
    method: 'post',
    data: data
  })
}
export default {
  getlist,
  policyDetail,
  getUserId,
  getSubscribe,
  addSubscribe
}
