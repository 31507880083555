import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vue.config.productionTip = false
// import 'amfe-flexible'

import Vant from 'vant'
import 'vant/lib/index.css'

import api from '@/api/policy'

import '@/iconfont/iconfont.css'
Vue.use(Vant)

var ua = window.navigator.userAgent.toLowerCase()

if (ua.match(/MicroMessenger/i) == 'micromessenger' && ua.match(/wxwork/i) == 'wxwork') {
  if (!getQueryString('code')) {
    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww258a2fa49b31bd99&redirect_uri=' + window.location.href.split('?')[0] + '&response_type=code&scope=snsapi_privateinfo&agentid=1000116#wechat_redirect'
  } else {
    api.getUserId({ code: getQueryString('code'), type: 'qw' }).then(res => {
      console.log(res)
      if (res.data.errcode === 0 || res.data.errcode === 200) {
        console.log(res.data.userid)
        store.state.userid = res.data.userid
      } else {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww258a2fa49b31bd99&redirect_uri=' + window.location.href.split('?')[0] + '&response_type=code&scope=snsapi_privateinfo&agentid=1000116#wechat_redirect'
      }
    })
  }
} else if (ua.match(/micromessenger/i) == 'micromessenger') {
  if (!getQueryString('code')) {
    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx815ef5b360934eb0&redirect_uri=' + encodeURIComponent(window.location.href.split('?')[0]) + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
  } else {
    api.getUserId({ code: getQueryString('code'), type: 'wx' }).then(res => {
      if (res.data.errcode === 0 || res.data.errcode === 200) {
        console.log(res.data.userid)
        store.state.userid = res.data.userid
      } else {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx815ef5b360934eb0&redirect_uri=' + encodeURIComponent(window.location.href.split('?')[0]) + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
      }
    })
  }
}

/**
 * ! 截取url参数
 */
// eslint-disable-next-line no-unused-vars
function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
